import { NativeModules } from 'react-native';

const Insider = NativeModules.RNInsider;

function shouldNotProceed() {
  return Insider == null;
}

function generateJSONErrorString(error) {
  return '[JavaScript Error] ' + error;
}


const ADD_EMAIL = 'addEmail';
const ADD_PHONE_NUMBER = 'addPhoneNumber';
const ADD_USER_ID = 'addUserID';
const ADD_CUSTOM_IDENTIFIER = 'addCustomIdentifier';

export default class RNInsiderIdentifier {
  identifiers = {};
  constructor() {
    this.identifiers = {};
  }

  addEmail(email: string) {
    if (shouldNotProceed() || email == null) return this;
    try {
      this.identifiers[ADD_EMAIL] = email.toString();
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
    return this;
  }

  addPhoneNumber(phoneNumber: string) {
    if (shouldNotProceed() || phoneNumber == null) return this;
    try {
      this.identifiers[ADD_PHONE_NUMBER] = phoneNumber.toString();
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
    return this;
  }

  addUserID(userID: string) {
    if (shouldNotProceed() || userID == null) return this;
    try {
      this.identifiers[ADD_USER_ID] = userID.toString();
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
    return this;
  }

  addCustomIdentifier(key: string, value: string) {
    if (shouldNotProceed() || key == null || value == null) return this;
    try {
      this.identifiers[key.toString()] = value.toString();
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
    return this;
  }

}