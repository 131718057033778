import { NativeModules } from 'react-native';

const Insider = NativeModules.RNInsider;

function shouldNotProceed() {
  return Insider == null;
}

function generateJSONErrorString(error) {
  return '[JavaScript Error] ' + error;
}

export default class RNInsiderEvent {
  name = '';
  parameters = {};
  constructor(name: string) {
    this.name = name;
  }

  addParameterWithString(key: string, value: string) {
    if (shouldNotProceed() || key == null || value == null) return this;
    try {
      this.parameters[key] = value;
      return this;
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
    return this;
  }

  addParameterWithInt(key: string, value: number) {
    if (shouldNotProceed() || key == null || value == null) return this;
    try {
      this.parameters[key] = value;
      return this;
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
    return this;
  }

  addParameterWithDouble(key: string, value: number) {
    if (shouldNotProceed() || key == null || value == null) return this;
    try {
      this.parameters[key] = value;
      return this;
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
    return this;
  }

  addParameterWithBoolean(key: string, value: boolean) {
    if (shouldNotProceed() || key == null || value == null) return this;
    try {
      this.parameters[key] = value;
      return this;
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
    return this;
  }

  addParameterWithDate(key: string, value: Date) {
    if (shouldNotProceed() || key == null || value == null) return this;
    try {
      this.parameters[key] = value.toISOString();
      return this;
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
    return this;
  }

  addParameterWithArray(key: string, value: Array<string>) {
    if (shouldNotProceed() || key == null || value == null) return this;
    try {
      this.parameters[key] = value;
      return this;
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
    return this;
  }

  build() {
    if (shouldNotProceed()) return;
    try {
      Insider.tagEvent(this.name, this.parameters);
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }
}
