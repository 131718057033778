import { NativeModules } from 'react-native';
import RNInsiderIdentifier from 'react-native-insider/src/InsiderIdentifier';

const Insider = NativeModules.RNInsider;

function shouldNotProceed() {
    return Insider == null;
}

function generateJSONErrorString(error) {
    return '[JavaScript Error] ' + error;
}

export default class RNInsiderUser {
    setGender(value: number) {
        if (shouldNotProceed() || value == null) return this;
        try {
            Insider.setGender(value);
        } catch (error) {
            Insider.putErrorLog(generateJSONErrorString(error));
        }
        return this;
    }

    setBirthday(value: Date) {
        if (shouldNotProceed() || value == null) return this;
        try {
            Insider.setBirthday(value.toISOString());
        } catch (error) {
            Insider.putErrorLog(generateJSONErrorString(error));
        }
        return this;
    }

    setName(value: string) {
        if (shouldNotProceed() || value == null) return this;
        try {
            Insider.setName(value);
        } catch (error) {
            Insider.putErrorLog(generateJSONErrorString(error));
        }
        return this;
    }

    setSurname(value: string) {
        if (shouldNotProceed() || value == null) return this;
        try {
            Insider.setSurname(value);
        } catch (error) {
            Insider.putErrorLog(generateJSONErrorString(error));
        }
        return this;
    }

    setAge(value: number) {
        if (shouldNotProceed() || value == null) return this;
        try {
            Insider.setAge(value);
        } catch (error) {
            Insider.putErrorLog(generateJSONErrorString(error));
        }
        return this;
    }

     setEmail(value: string) {
       if (shouldNotProceed() || value == null) return this;
       try {
         Insider.setEmail(value);
       } catch (error) {
         Insider.putErrorLog(generateJSONErrorString(error));
       }
       return this;
     }

     setPhoneNumber(value: string) {
       if (shouldNotProceed() || value == null) return this;
       try {
         Insider.setPhoneNumber(value);
       } catch (error) {
         Insider.putErrorLog(generateJSONErrorString(error));
       }
       return this;
     }

    setLanguage(value: string) {
        if (shouldNotProceed() || value == null) return this;
        try {
            Insider.setLanguage(value);
        } catch (error) {
            Insider.putErrorLog(generateJSONErrorString(error));
        }
        return this;
    }

    setLocale(value: string) {
        if (shouldNotProceed() || value == null) return this;
        try {
            Insider.setLocale(value);
        } catch (error) {
            Insider.putErrorLog(generateJSONErrorString(error));
        }
        return this;
    }

    setFacebookID(value: string) {
        if (shouldNotProceed() || value == null) return this;
        try {
            Insider.setFacebookID(value);
        } catch (error) {
            Insider.putErrorLog(generateJSONErrorString(error));
        }
        return this;
    }

    setTwitterID(value: string) {
        if (shouldNotProceed() || value == null) return this;
        try {
            Insider.setTwitterID(value);
        } catch (error) {
            Insider.putErrorLog(generateJSONErrorString(error));
        }
        return this;
    }

    setEmailOptin(value: boolean) {
        if (shouldNotProceed() || value == null) return this;
        try {
            Insider.setEmailOptin(value);
        } catch (error) {
            Insider.putErrorLog(generateJSONErrorString(error));
        }
        return this;
    }

    setSMSOptin(value: boolean) {
        if (shouldNotProceed() || value == null) return this;
        try {
            Insider.setSMSOptin(value);
        } catch (error) {
            Insider.putErrorLog(generateJSONErrorString(error));
        }
        return this;
    }

    setPushOptin(value: boolean) {
        if (shouldNotProceed() || value == null) return this;
        try {
            Insider.setPushOptin(value);
        } catch (error) {
            Insider.putErrorLog(generateJSONErrorString(error));
        }
        return this;
    }

    setLocationOptin(value: boolean) {
        if (shouldNotProceed() || value == null) return this;
        try {
            Insider.setLocationOptin(value);
        } catch (error) {
            Insider.putErrorLog(generateJSONErrorString(error));
        }
        return this;
    }

    setWhatsappOptin(value: boolean) {
        if (shouldNotProceed() || value == null) return this;
        try {
            Insider.setWhatsappOptin(value);
        } catch (error) {
            Insider.putErrorLog(generateJSONErrorString(error));
        }
        return this;
    }

    login(identifiers: RNInsiderIdentifier, insiderIDResult: Function) {
        if (shouldNotProceed()) { return this; }
        try {
            if (insiderIDResult != null) {
                Insider.loginWithReturningID(identifiers.identifiers, insiderIDResult);

                return;
            }

            Insider.login(identifiers.identifiers);
        } catch (error) {
            Insider.putErrorLog(generateJSONErrorString(error));
        }
        return this;
    }

    logout() {
        if (shouldNotProceed()) return this;
        try {
            Insider.logout();
        } catch (error) {
            Insider.putErrorLog(generateJSONErrorString(error));
        }
        return this;
    }

    setCustomAttributeWithString(key: string, value: string) {
        if (shouldNotProceed() || key == null || value == null) return this;
        try {
            Insider.setCustomAttributeWithString(key, value);
        } catch (error) {
            Insider.putErrorLog(generateJSONErrorString(error));
        }
        return this;
    }

    setCustomAttributeWithInt(key: string, value: number) {
        if (shouldNotProceed() || key == null || value == null) return this;
        try {
            Insider.setCustomAttributeWithInt(key, value);
        } catch (error) {
            Insider.putErrorLog(generateJSONErrorString(error));
        }
        return this;
    }

    setCustomAttributeWithDouble(key: string, value: number) {
        if (shouldNotProceed() || key == null || value == null) return this;
        try {
            Insider.setCustomAttributeWithDouble(key, value);
        } catch (error) {
            Insider.putErrorLog(generateJSONErrorString(error));
        }
        return this;
    }

    setCustomAttributeWithBoolean(key: string, value: boolean) {
        if (shouldNotProceed() || key == null || value == null) return this;
        try {
            Insider.setCustomAttributeWithBoolean(key, value);
        } catch (error) {
            Insider.putErrorLog(generateJSONErrorString(error));
        }
        return this;
    }

    setCustomAttributeWithDate(key: string, value: Date) {
        if (shouldNotProceed() || key == null || value == null) return this;
        try {
            Insider.setCustomAttributeWithDate(key, value.toISOString());
        } catch (error) {
            Insider.putErrorLog(generateJSONErrorString(error));
        }
        return this;
    }

    setCustomAttributeWithArray(key: string, value: string[]) {
        if (shouldNotProceed() || key == null || value == null) return this;
        try {
            Insider.setCustomAttributeWithArray(key, value);
        } catch (error) {
            Insider.putErrorLog(generateJSONErrorString(error));
        }
        return this;
    }

    unsetCustomAttribute(key: string) {
        if (shouldNotProceed() || key == null) return this;
        try {
            Insider.unsetCustomAttribute(key);
        } catch (error) {
            Insider.putErrorLog(generateJSONErrorString(error));
        }
        return this;
    }
}