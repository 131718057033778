import { NativeModules } from 'react-native';

const Insider = NativeModules.RNInsider;

function shouldNotProceed() {
  return Insider == null;
}

function generateJSONErrorString(error) {
  return '[JavaScript Error] ' + error;
}

// Must
const PRODUCT_ID = 'product_id';
const PRODUCT_NAME = 'name';
const TAXONOMY = 'taxonomy';
const CURRENCY = 'currency';
const UNIT_PRICE = 'unit_price';
const IMAGE_URL = 'image_url';

// Optional
const SUB_CATEGORY = 'sub_category';
const SALE_PRICE = 'sale_price';
const STOCK = 'stock';
const COLOR = 'color';
const SIZE = 'size';
const QUANTITY = 'quantity';
const SHIPPING_COST = 'shipping_cost';
const VOUCHER_NAME = 'voucher_name';
const VOUCHER_DISCOUNT = 'voucher_discount';
const PROMOTION_NAME = 'promotion_name';
const PROMOTION_DISCOUNT = 'promotion_discount';
const GROUP_CODE = 'groupcode';

export default class RNInsiderProduct {
  productMustMap = {};
  productOptMap = {};
  constructor(productID: string, name: string, taxonomy: Array<string>, imageURL: string, price: number, currency: string) {
    this.productMustMap = {
      product_id: productID,
      name: name,
      taxonomy: taxonomy,
      image_url: imageURL,
      unit_price: price,
      currency: currency,
    };
  }

  setColor(color: string) {
    if (shouldNotProceed() || color == null) return this;
    try {
      this.productOptMap[COLOR] = color;
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
    return this;
  }

  setVoucherName(voucherName: string) {
    if (shouldNotProceed() || voucherName == null) return this;
    try {
      this.productOptMap[VOUCHER_NAME] = voucherName;
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
    return this;
  }

  setPromotionName(promotionName: string) {
    if (shouldNotProceed() || promotionName == null) return this;
    try {
      this.productOptMap[PROMOTION_NAME] = promotionName;
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
    return this;
  }

  setSize(size: string) {
    if (shouldNotProceed() || size == null) return this;
    try {
      this.productOptMap[SIZE] = size;
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
    return this;
  }

  setSalePrice(salePrice: number) {
    if (shouldNotProceed() || salePrice == null) return this;
    try {
      this.productOptMap[SALE_PRICE] = salePrice;
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
    return this;
  }

  setShippingCost(shippingCost: number) {
    if (shouldNotProceed() || shippingCost == null) return this;
    try {
      this.productOptMap[SHIPPING_COST] = shippingCost;
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
    return this;
  }

  setVoucherDiscount(voucherDiscount: number) {
    if (shouldNotProceed() || voucherDiscount == null) return this;
    try {
      this.productOptMap[VOUCHER_DISCOUNT] = voucherDiscount;
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
    return this;
  }

  setPromotionDiscount(promotionDiscount: number) {
    if (shouldNotProceed() || promotionDiscount == null) return this;
    try {
      this.productOptMap[PROMOTION_DISCOUNT] = promotionDiscount;
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
    return this;
  }

  setStock(stock: number) {
    if (shouldNotProceed() || stock == null) return this;
    try {
      this.productOptMap[STOCK] = stock;
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
    return this;
  }

  setQuantity(quantity: number) {
    if (shouldNotProceed() || quantity == null) return this;
    try {
      this.productOptMap[QUANTITY] = quantity;
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
    return this;
  }

  setGroupCode(groupCode: string) {
    if (shouldNotProceed() || groupCode == null) return this;
    try {
      this.productOptMap[GROUP_CODE] = groupCode;
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
    return this;
  }

  setCustomAttributeWithString(key: string, value: string) {
    if (shouldNotProceed() || key == null || value == null) return this;
    try {
      this.productOptMap[key] = value;
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
    return this;
  }

  setCustomAttributeWithInt(key: string, value: number) {
    if (shouldNotProceed() || key == null || value == null) return this;
    try {
      this.productOptMap[key] = value;
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
    return this;
  }

  setCustomAttributeWithBoolean(key: string, value: boolean) {
    if (shouldNotProceed() || key == null || value == null) return this;
    try {
      this.productOptMap[key] = value;
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
    return this;
  }

  setCustomAttributeWithDouble(key: string, value: number) {
    if (shouldNotProceed() || key == null || value == null) return this;
    try {
      this.productOptMap[key] = value;
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
    return this;
  }

  setCustomAttributeWithDate(key: string, value: Date) {
    if (shouldNotProceed() || key == null || value == null) return this;
    try {
      this.productOptMap[key] = value.toISOString();
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
    return this;
  }

  setCustomAttributeWithArray(key: string, value: Array<string>) {
    if (shouldNotProceed() || key == null || value == null) return this;
    try {
      this.productOptMap[key] = value;
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
    return this;
  }
}
