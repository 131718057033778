import { Platform, NativeModules, NativeEventEmitter } from 'react-native';

import RNInsiderEvent from './src/InsiderEvent';
import RNInsiderUser from './src/InsiderUser';
import RNInsiderProduct from './src/InsiderProduct';

var packageJSON = require('./package.json');

const Insider = NativeModules.RNInsider;
const InsiderUser = new RNInsiderUser();
const NotificationHandler = NativeModules.RNNotificationHandler;
const eventHandler = new NativeEventEmitter(NotificationHandler);

const NOTIFICATION_OPEN = 'NOTIFICATION_OPEN';
const INAPP_BUTTON_CLICK = 'INAPP_BUTTON_CLICK';
const TEMP_STORE_PURCHASE = 'TEMP_STORE_PURCHASE';
const TEMP_STORE_ADDED_TO_CART = 'TEMP_STORE_ADDED_TO_CART';
const TEMP_STORE_CUSTOM_ACTION = 'TEMP_STORE_CUSTOM_ACTION';
const FOREGROUND_PUSH = 'FOREGROUND_PUSH';
const INAPP_SEEN = 'INAPP_SEEN';

const platformType = {
    ios: "ios",
    android: "android"
}

function shouldNotProceed() {
  return Insider == null;
}

function generateJSONErrorString(error) {
  return '[JavaScript Error] ' + error;
}

function registerInsiderCallback(insiderCallback) {
  try {
    const callbackActions = [NOTIFICATION_OPEN, INAPP_BUTTON_CLICK, TEMP_STORE_PURCHASE, TEMP_STORE_ADDED_TO_CART, TEMP_STORE_CUSTOM_ACTION, INAPP_SEEN];
    callbackActions.forEach((callbackAction, index) => {
      eventHandler.addListener(callbackAction, notification => {
        insiderCallback(index, notification);
      });
    });
  } catch (error) {
    Insider.putErrorLog(generateJSONErrorString(error));
  }
}
export default class RNInsider {
  static init(partnerName: string, appGroup: string, insiderCallback: Function) {
    if (shouldNotProceed() || partnerName == null || appGroup == null || insiderCallback == null) return;
    try {
      registerInsiderCallback(insiderCallback);
      let sdkVersion = 'RN-' + packageJSON.version;
      if (Platform.OS === platformType.ios) {
        Insider.initWithLaunchOptions(null, partnerName, appGroup, sdkVersion);
      } else {
        Insider.init(partnerName, sdkVersion);
      }
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }

  static initWithCustomEndpoint(partnerName: string, appGroup: string, endpoint: string, insiderCallback: Function) {
    if (shouldNotProceed() || partnerName == null || appGroup == null || endpoint == null || insiderCallback == null) return;
    try {
      registerInsiderCallback(insiderCallback);
      let sdkVersion = 'RN-' + packageJSON.version;
      if (Platform.OS === platformType.ios) {
        Insider.initWithCustomEndpoint(null, partnerName, appGroup, endpoint, sdkVersion);
      } else {
        Insider.init(partnerName, sdkVersion);
        Insider.setCustomEndpoint(endpoint);
      }
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }

  static reinitWithPartnerName(partnerName: string) {
    if (shouldNotProceed() || partnerName == null) return;

    try {
      Insider.reinitWithPartnerName(partnerName);
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }

  static tagEvent(name: string): ?RNInsiderEvent {
    if (shouldNotProceed() || name == null) return new RNInsiderEvent('');
    return new RNInsiderEvent(name);
  }

  static getCurrentUser(): ?RNInsiderUser {
    if (shouldNotProceed()) return;
    return InsiderUser;
  }

  static setGDPRConsent(gdprConsent) {
    if (shouldNotProceed() || gdprConsent == null) return;
    try {
      Insider.setGDPRConsent(gdprConsent);
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }

  // Product

  static createNewProduct(
    productID: string,
    name: string,
    taxonomy: Array<string>,
    imageURL: string,
    price: number,
    currency: string
  ): ?RNInsiderProduct {
    if (shouldNotProceed() || productID == null || name == null || taxonomy == null || imageURL == null || price == null || currency == null)
      return new RNInsiderProduct('', '', [], '', 0, '');
    return new RNInsiderProduct(productID, name, taxonomy, imageURL, price, currency);
  }

  static itemPurchased(uniqueSaleID: string, product: RNInsiderProduct) {
    if (shouldNotProceed() || uniqueSaleID == null || product == null) return;
    try {
      Insider.itemPurchased(uniqueSaleID, product.productMustMap, product.productOptMap);
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }

  static itemAddedToCart(product: RNInsiderProduct) {
    if (shouldNotProceed() || product == null) return;
    try {
      Insider.itemAddedToCart(product.productMustMap, product.productOptMap);
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }

  static itemRemovedFromCart(productID: string) {
    if (shouldNotProceed() || productID == null) return;
    try {
      Insider.itemRemovedFromCart(productID);
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }

  static cartCleared() {
    if (shouldNotProceed()) return;
    try {
      Insider.cartCleared();
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }

  // Message Center & Smart Recommendation
  static getMessageCenterData(limit: number, startDate: Date, endDate: Date, callback: Function) {
    if (shouldNotProceed() || limit == null || startDate == null || endDate == null || callback == null || startDate.getTime() === endDate.getTime() ||
    startDate.getTime() > endDate.getTime()) return;
    try {
      Insider.getMessageCenterData(limit, startDate.toISOString(), endDate.toISOString(), (messageCenterData) => {
        if (Platform.OS !== platformType.ios) {
          callback(messageCenterData);
          return;
        }
        callback(messageCenterData.data);
      });
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }
    
  static getMessageCenterDataArray(limit: number, startDate: Date, endDate: Date, callback: Function) {
        if (shouldNotProceed() || limit == null || startDate == null || endDate == null || callback == null || startDate.getTime() === endDate.getTime() ||
            startDate.getTime() > endDate.getTime()) return;
        try {
            Insider.getMessageCenterData(limit, startDate.toISOString(), endDate.toISOString(), (messageCenterData) => {
                if (Platform.OS !== platformType.ios) {
                    callback(JSON.parse(messageCenterData || '[]'));
                    return;
                }
                callback(messageCenterData.data);
            });
        } catch (error) {
            Insider.putErrorLog(generateJSONErrorString(error));
        }
  }

  static getSmartRecommendation(recommendationID: number, locale: string, currency: string, callback: Function) {
    if (shouldNotProceed() || recommendationID == null || locale == null || currency == null || callback == null) return;
    try {
      Insider.getSmartRecommendation(recommendationID, locale, currency, (recommendation) => {
        callback(recommendation);
      });
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }

  static getSmartRecommendationWithProduct(product: RNInsiderProduct, recommendationID: number, locale: string, callback: Function) {
    if (shouldNotProceed() || product == null || recommendationID == null || locale == null || callback == null) return;
    try {
      Insider.getSmartRecommendationWithProduct(product.productMustMap, product.productOptMap, recommendationID, locale, (recommendation) => {
        callback(recommendation);
      });
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }

  static getSmartRecommendationWithProductIDs(productIDs: Array<string>, recommendationID: number, locale: string, currency: string, callback: Function) {
    if (shouldNotProceed() || productIDs == null || recommendationID == null || locale == null || currency == null || callback == null) return;
    try {
      productIDs = productIDs.filter(value => value != null && typeof value == "string" && value.trim());

      Insider.getSmartRecommendationWithProductIDs(productIDs, recommendationID, locale, currency, (recommendation) => {
        callback(recommendation);
      });
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }

  static clickSmartRecommendationProduct(recommendationID: number, product: RNInsiderProduct) {
    if (shouldNotProceed() || product == null || recommendationID == null) return;
    try {
      Insider.clickSmartRecommendationProduct(recommendationID, product.productMustMap, product.productOptMap);
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }

  // Content Optimizer
  static getContentStringWithName(variableName: string, defaultValue: string, contentOptimizerDataType: number, callback: Function) {
    if (shouldNotProceed() || variableName == null || defaultValue == null || contentOptimizerDataType == null || callback == null) return;
    try {
      Insider.getContentStringWithName(variableName, defaultValue, contentOptimizerDataType, callback);
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }

  static getContentBoolWithName(variableName: string, defaultValue: boolean, contentOptimizerDataType: number, callback: Function) {
    if (shouldNotProceed() || variableName == null || defaultValue == null || contentOptimizerDataType == null || callback == null) return;
    try {
      Insider.getContentBoolWithName(variableName, defaultValue, contentOptimizerDataType, callback);
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }

  static getContentIntWithName(variableName: string, defaultValue: number, contentOptimizerDataType: number, callback: Function) {
    if (shouldNotProceed() || variableName == null || defaultValue == null || contentOptimizerDataType == null || callback == null) return;
    try {
      Insider.getContentIntWithName(variableName, defaultValue, contentOptimizerDataType, callback);
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }

  // Visit Pages
  static visitHomePage() {
    if (shouldNotProceed()) return;
    try {
      Insider.visitHomePage();
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }

  static visitListingPage(taxonomy: Array<string>) {
    if (shouldNotProceed() || taxonomy == null) return;
    try {
      Insider.visitListingPage(taxonomy);
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }

  static visitProductDetailPage(product: RNInsiderProduct) {
    if (shouldNotProceed() || product == null) return;
    try {
      Insider.visitProductDetailPage(product.productMustMap, product.productOptMap);
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }

  static visitCartPage(products: Array<RNInsiderProduct>) {
    if (shouldNotProceed() || products == null) return;
    let mappedProducts = new Array(products.length);
    try {
        products.forEach((product, i) => {
            let productMap = {};
            productMap['productMustMap'] = product.productMustMap;
            productMap['productOptMap'] = product.productOptMap;
            mappedProducts[i] = productMap;
          });
      Insider.visitCartPage(mappedProducts);
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }

  static startTrackingGeofence() {
    if (shouldNotProceed()) return;
    try {
      Insider.startTrackingGeofence();
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }

  static handleNotification(notification) {
    if (shouldNotProceed() || notification == null) return;
    try {
      Insider.handleNotification(notification);
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }

  static enableIDFACollection(enableIDFACollection: boolean) {
    if (shouldNotProceed() || enableIDFACollection == null) return;
    try {
      Insider.enableIDFACollection(enableIDFACollection);
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }

  static removeInapp() {
    if (shouldNotProceed()) return;
    try {
      Insider.removeInapp();
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }

  static registerWithQuietPermission(enabled) {
    if (shouldNotProceed() || enabled == null) return;
    try {
      if (Platform.OS !== platformType.ios) return;
      Insider.registerWithQuietPermission(enabled);
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }

  static setHybridPushToken(token: string) {
    if (shouldNotProceed() || token == null) return;
    try {
      if (Platform.OS !== 'android') return;
      Insider.setHybridPushToken(token);
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }

  static showNativeAppReview() {
    if (shouldNotProceed()) return;
    try {
      Insider.showNativeAppReview();
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }

  static enableLocationCollection (consentStatus) {
    if (shouldNotProceed()) return;

    try {
      Insider.enableLocationCollection(consentStatus);
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }

  static enableIpCollection (consentStatus) {
    if (shouldNotProceed()) return;

    try {
      Insider.enableIpCollection(consentStatus);
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }

  static enableCarrierCollection (consentStatus) {
    if (shouldNotProceed()) return;

    try {
      Insider.enableCarrierCollection(consentStatus);
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }

  static signUpConfirmation() {
    if (shouldNotProceed()) return;

    try {
      Insider.signUpConfirmation();
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }

  static reenableTemplatesForIOS() {
    if (shouldNotProceed() || Platform.OS !== platformType.ios) return;

    try {
      Insider.reenableTemplates();
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }

  static disableTemplatesForIOS() {
    if (shouldNotProceed() || Platform.OS !== platformType.ios) return;

    try {
      Insider.disableTemplates();
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }

  static setForegroundPushCallback (callback) {
    if (shouldNotProceed()) return;

    try {
      Insider.setForegroundPushCallback();

      eventHandler.addListener(FOREGROUND_PUSH, notification => {
        callback(notification);
      });
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }

  static setActiveForegroundPushView() {
    if (shouldNotProceed()) return;

    try {
      Insider.setActiveForegroundPushView();
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }

  static handleUniversalLink(url: string) {
    try {
      if (Platform.OS !== 'android' || url == null || shouldNotProceed()) return;

      Insider.handleUniversalLink(url);
    } catch (error) {
      Insider.putErrorLog(generateJSONErrorString(error));
    }
  }
}
